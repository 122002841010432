export function smartTrim(str, length, delim, appendix) {
  if (str.length <= length) return str;
  let trimmedStr = str.substr(0, length + delim.length);
  let lastDelimIndex = trimmedStr.lastIndexOf(delim);
  if (lastDelimIndex >= 0) trimmedStr = trimmedStr.substr(0, lastDelimIndex);
  if (trimmedStr) trimmedStr += appendix;
  return trimmedStr;
}

export function getTopicNameById (id, blogTopics) {
  // console.log(blogTopics);
  for (let i = 0; i < blogTopics.length; i++) {
    if (blogTopics[i].id === id) {
      return blogTopics[i].name;
    }
    if (id === 0) {
      return 'Case Study';
    }
    if (id === 1) {
      return 'Company';
    }
  }
}