import React, {useState} from "react";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {graphql} from 'gatsby';
import LatestBlogNews from '../../components/blog/LatestBlogNews';
import blogCategories from '../../components/blog/categories';
import BlogNav  from '../../components/blog/blog-nav';


const IndexPage = (props) => {
  const {t} = useTranslation();
  return (
      <Layout>
        <Seo title={t('blog_title')} description={t('des_blog')}/>
        <div className="blog">
          <section className="hero">
            <h1>Digilock <Trans>blog</Trans></h1>
            <div className="container">
              <BlogNav />
              <LatestBlogNews
                  blogData={props.data.allHubspotPost.edges}
                  blogTopics={blogCategories}
                  whichPage={'aspire'}/>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    
  allHubspotPost(limit: 100) {
    edges {
      node {
        title,
        url,
        body,
        created,
        slug,
        topics {
          id,
          name,
        },
        feature_image {
          url,
          alt_text
        }
      } 
    }
  }
}

`;


