import React, { Component } from 'react';
import LatestBlogNewsContainer from './LatestBlogNewsContainer';

export function createMarkup(markup) {
  return { __html: markup }
}

class LatestBlogNews extends Component {
  constructor(props) {
    super(props)
    this.state = {
      blogData: null
    }
  }

  isBrowser = typeof window !== "undefined";

  // componentWillMount() {
  //   let newBlogData = this.props.blogData;
  //
  //   if(this.isBrowser && window.location.search.length > 0) {
  //     this.setFilter(window.location.search.split('=')[1]);
  //   } else {
  //     this.setState({
  //       blogData: newBlogData
  //     })
  //   }
  // }

  render() {
    let blogTopics = this.props.blogTopics.data.objects;
    return (
        <div>
            <LatestBlogNewsContainer
                blogTopics={blogTopics}
                blogData={this.props.blogData}
            />
        </div>
    )
  }
}

export default LatestBlogNews;