import React, { Component } from 'react';
import {Link} from "gatsby";
import {replaceCharWithChar} from '../../utils/utils';
import {smartTrim} from "../../utils/blogUtils";
import CustomLocalizedLink from '../../components/locales/custom-localized-link';


class LatestBlogNewsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visibleItems: 7
    }
  }

  postCount = 7;

  handleShowMore = () => {
    let count = this.state.visibleItems;
    this.setState({visibleItems: count + this.postCount});
  }

  render() {
    return (
        <div className="latest-news-container">
          <div className="frontpage">
            {this.props.blogData
              .slice(0, 1).map(({ node: post }, index) => {
                if (typeof window !== 'undefined') {

                  let parser = new DOMParser();
                  let doc = parser.parseFromString(post.body, 'text/html');
                  let topics = post.topics;
                  let pText = '';
                  pText = smartTrim(doc.body.innerText, 250, ' ', '...').trim();


                  pText = replaceCharWithChar(pText, '', '');
                  let bUrl = '/blog/post/' + post.slug+'/';
                  // let topicName = getTopicNameById(topics[0].id, this.props.blogTopics);
                  let topicName = topics[0].name;


                  if (index === 0) {
                    return (
                        <div>
                          <div className="post-item">
                            <Link to={bUrl}>
                              <img className={'img-responsive'} src={post.feature_image.url} alt="blog post"
                                   loading="lazy"/>
                            </Link>
                            <CustomLocalizedLink
                                goto={'/blog/category/'+topics[index].name+'/'}
                                label={topicName}
                                cls={'category-label'} />
                            <div className={'hero-copy'}>
                              <Link className={'hero-title'} to={index === 1 ? '/about/digilock-showroom/' : bUrl}>
                                <h2>{post.title}</h2>
                              </Link>
                              <p dangerouslySetInnerHTML={{__html: pText}}/>
                            </div>

                          </div>
                        </div>
                    )
                  }
                }
              })}
          </div>
          <div className="secondpage">
            {this.props.blogData
              .slice(1, this.state.visibleItems).map(({ node: post }, index) => {
                if (typeof window !== 'undefined') {
                  let parser = new DOMParser();
                  let topics = post.topics;
                  let topicName = topics[0].name;
                  let topicId = topics[0].id;

                  return (
                    <div key={index}>

                      <div className="post-item">
                        <Link to={'/blog/post/' + post.slug+'/'}>
                          <img className={'img-responsive'} src={post.feature_image.url} alt="blog post"
                               loading="lazy"/>
                        </Link>
                        <CustomLocalizedLink
                            goto={'/blog/category/'+topicName+'/'}
                            label={topicName}
                            cls={topicName !== 'Pro Sports' ? 'category-label' : 'category-label turn-off'} />
                        <h2>{post.title}</h2>
                      </div>
                    </div>
                  )
                }
              })}
          </div>
          {this.state.visibleItems < this.props.blogData.length && (
              <button
                  onClick={this.handleShowMore}
                  className={'btn btn--orange'}
              >
                Show More
              </button>
          )}
        </div>

    )
  }
}

export default LatestBlogNewsContainer;